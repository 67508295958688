import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CompatibleImg } from '../components/Img';
import PageLayout from '../components/PageLayout';
import { RichTextView, device, ContentMain } from '../components/commonStyled';
import Sections from '../components/Sections';
import { Box } from '../components/Box';

const regexHeading = new RegExp('<h2>(.*?)</h2>', 'g');

export default ({ data, location }) => {
    const {
        title,
        content,
        featured_media: featured_media_raw,
        seo,
        common_acf: { blocks },
    } = data?.wpPage;

    const { node: featured_media } = featured_media_raw || {};

    const items = (content as string)
        .split(regexHeading)
        .map((s) => s.trim())
        .splice(1);

    const questions = items.filter((_, index) => index % 2 === 0);
    const answers = items.filter((item, index) => index % 2 !== 0);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (
        event: React.ChangeEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <PageLayout
            breadcrumbs={[
                {
                    title,
                    url: location?.pathname,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <Container>
                {featured_media?.localFile && (
                    <ImageWrap>
                        <CompatibleImg image={featured_media.localFile} />
                    </ImageWrap>
                )}
                <Box>
                    {questions.map((question, index) => (
                        <Accordion
                            key={question}
                            expanded={expanded === question}
                            onChange={handleChange(question)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RichTextView
                                    dangerouslySetInnerHTML={{
                                        __html: question,
                                    }}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <RichTextView
                                    dangerouslySetInnerHTML={{
                                        __html: answers[index],
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Sections enabledSections={blocks} />
        </PageLayout>
    );
};

const Container = styled(ContentMain)`
    display: block;
`;

const ImageWrap = styled.div`
    width: 100%;
    height: 400px;
    border-radius: 33px;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);
    overflow: hidden;

    margin-bottom: 48px;

    @media ${device.laptopSmall} {
        overflow: hidden;
        margin-bottom: 36px;
        margin-right: 0;
    }

    @media ${device.mobileMedium} {
        width: 100%;
        margin-bottom: 24px;
    }
`;

export const pageQuery = graphql`
    query FAQPage {
        wpPage(common_acf: { id: { eq: "faq" } }) {
            title
            content
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
            featured_media: featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 800)
                        }
                    }
                }
            }
        }
    }
`;
